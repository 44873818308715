<script>
export default {
  name: 'DiveButton',
  props: {
    text: String,
    type: String,
    size: String,
    disabled: Boolean,
    loading: Boolean,
  },
  emits: ['click'],
};
</script>

<template>
  <button
    class="dive-button"
    :class="['type-' + type, 'size-' + size, { loading: loading }]"
    :disabled="disabled || loading"
    @click="$emit('click')"
  >
    <div v-if="loading" class="spinner"></div>
    <span>{{ text }}</span>
  </button>
</template>

<style scoped lang="scss">
.dive-button {
  font-family: Raleway, sans-serif;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition:
    background-color 0.3s,
    border-color 0.3s,
    color 0.3s;

  &.size-small {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
  }

  &.size-medium {
    font-size: 16px;
    line-height: 24px;
    padding: 13px 40px;
  }

  &.size-large {
    font-size: 20px;
    line-height: 28px;
    padding: 16px 50px;
  }

  &.type-primary {
    color: #fff;
    background-color: #0a7cff;
    border: 1px solid #0a7cff;

    &:hover:not(:disabled):not(.loading) {
      background-color: #52a5ff;
      border-color: #2f85e0;
    }

    &:active:not(:disabled):not(.loading) {
      background-color: #1e90ff;
      border-color: #1c86ee;
    }

    &:disabled:not(.loading) {
      background-color: #d3d3d3;
      border-color: #d3d3d3;
      cursor: not-allowed;
    }
  }

  &.type-secondary {
    color: #0a7cff;
    background-color: #fff;
    border: 1px solid #0a7cff;

    &:hover:not(:disabled):not(.loading) {
      background-color: #f0f8ff;
    }

    &:active:not(:disabled):not(.loading) {
      background-color: #e6f3ff;
    }

    &:disabled:not(.loading) {
      color: #d3d3d3;
      border-color: #d3d3d3;
      cursor: not-allowed;
    }
  }

  &.type-link {
    color: #0a7cff;
    background-color: transparent;
    border: none;
    padding: 0;

    &:hover:not(:disabled):not(.loading) {
      text-decoration: underline;
      color: #52a5ff;
    }

    &:active:not(:disabled):not(.loading) {
      color: #1e90ff;
    }

    &:disabled:not(.loading) {
      color: #d3d3d3;
      cursor: not-allowed;
    }
  }

  &.loading {
    pointer-events: none;
    opacity: 0.6;

    .spinner {
      margin-right: 8px;
      border: 2px solid rgba(255, 255, 255, 0.3);
      border-top: 2px solid #fff;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      animation: spin 1s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
