<template>
  <div>Симуляторы</div>
  <div v-for="item in simulatorsList" :key="item.id" class="simulators-list">
    <accordion-list-item
      :title="item.title"
      :description="item.goal"
      :category="item.category"
    />
  </div>
</template>

<script>
import AccordionListItem from '@/shared/ui/AccordionListItem.vue';
import { ref } from 'vue';
export default {
  components: {
    AccordionListItem,
  },
  setup() {
    const simulatorsList = ref([
      {
        id: 1,
        title: 'Геодезическое обеспечение строительства нефтегазовых объектов',
        category: 'Нефтяная промышленность',
        goal: 'Обучение навыкам проведения измерений на строительных объектах с использованием тахеометра и анализ полученных данных для контроля деформаций.',
        tasks: [
          'Разметка местности и установка флажков.',
          'Настройка и калибровка тахеометра.',
          'Проведение измерений вручную и автоматически.',
          'Анализ данных и занесение результатов в журнал.',
          'Отправка отчёта в систему Moodle.',
        ],
        tools: ['Флажки', 'Рулетка', 'Тахеометр', 'Интерактивный планшет'],
        location: 'Резервуарный парк',
        format: 'VR-тренажер, доступный на ПК, Pico, Quest',
        safety_system:
          'Сценарии исключают ошибки, а обучение помогает предотвращать аварийные ситуации.',
        challenges: [
          'Точная настройка тахеометра',
          'Устранение ошибок при измерениях',
        ],
        development_time: '3 месяца',
        vr_objects: 60,
        virtual_space_area: '36 000 м²',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
      {
        title:
          'Электроснабжение. Распределение электроприемников по пунктам питания.',
        category: 'Электроэнергетика',
        goal: 'Обучение распределению электроприемников по пунктам питания, управление нагрузками.',
      },
    ]);
    return {
      simulatorsList,
    };
  },
};
</script>
