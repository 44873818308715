<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xml:space="preserve"
  >
    <g>
      <path
        d="M4.76 18.81h.11l2.93-.27c.41-.04.79-.22 1.08-.51L19.94 6.97c.52-.52.81-1.21.81-1.94s-.29-1.42-.81-1.94l-.71-.71c-1.04-1.04-2.85-1.04-3.89 0l-1.41 1.41-9.64 9.64c-.29.29-.47.67-.5 1.08l-.27 2.93c-.03.37.1.73.36 1 .24.24.55.37.88.37zM17.29 3.07c.32 0 .64.12.88.37l.71.71a1.234 1.234 0 0 1 0 1.76l-.88.88-2.47-2.47.88-.88c.24-.24.56-.37.88-.37zM5.28 14.65c0-.06.03-.11.07-.15l9.11-9.12 2.47 2.47-9.11 9.11s-.1.07-.15.07l-2.63.24.24-2.63zM22.75 22c0 .41-.34.75-.75.75H2c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h20c.41 0 .75.34.75.75z"
        fill="#39434B"
        :stroke="color"
        opacity="1"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
  props: {
    color: {
      type: String,
      default: '#39434B',
    },
  },
};
</script>

<style scoped lang="scss"></style>
