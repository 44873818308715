<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    x="0"
    y="0"
    viewBox="0 0 20 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="#39434B"
        fill-rule="evenodd"
        d="M9 15a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1zM3 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm0 5a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1z"
        clip-rule="evenodd"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
  props: {
    color: {
      type: String,
      default: '#39434B',
    },
  },
};
</script>

<style scoped lang="scss"></style>
