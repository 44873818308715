<template>
  <div class="collapse-text-card-wrapper">
    <div class="collapse-text-card" :class="{ active: isOpened || open }">
      <div class="collapse-text-card__header">
        <div class="collapse-text-card__header-container">
          <h3 class="collapse-text-card__title">
            {{ title }}
          </h3>
          <h5 class="collapse-text-card__category">
            {{ category }}
          </h5>
        </div>
        <div class="collapse-text-card__buttons-container">
          <StatusIcon
            class="collapse-text-card__edit-icon"
            @click="showChangeStatusModal = true"
          />
          <Teleport to="body">
            <DialogModal
              :show="showChangeStatusModal"
              @close="showChangeStatusModal = false"
            >
              <template #header>
                <h1>Изменение статуса</h1>
              </template>
              <template #body>
                Вы уверены, что хотите сменить статус?
              </template>
            </DialogModal>
          </Teleport>
          <EditIcon
            class="collapse-text-card__edit-icon"
            @click="toggleEditInfo"
          />
          <DeleteBinIcon
            class="collapse-text-card__delete-icon"
            @click="showModal = true"
          />
          <Teleport to="body">
            <DialogModal :show="showModal" @close="showModal = false">
              <template #header>
                <h1>Удаление методиста</h1>
              </template>
              <template #body>
                Вы уверены, что хотите удалить методиста?
              </template>
            </DialogModal>
          </Teleport>
          <MoreInfoIcon
            class="collapse-text-card__more-icon"
            @click="toggleInfo"
          />
        </div>
      </div>
      <Transition name="slide-fade">
        <div v-show="isOpened || open" class="collapse-text-card__body">
          <p v-if="loading">Загрузка данных...</p>
          <div v-else-if="methodist">
            <div><strong>ФИО:</strong> {{ methodist.name }}</div>
            <div><strong>Биография:</strong> {{ methodist.bio }}</div>
            <div><strong>Должность:</strong> {{ methodist.position }}</div>
            <div>
              <strong>Тренажеры:</strong>
              <div
                v-for="simulator in methodist.application"
                :key="simulator.id"
                class="collapse-text-card__application-info"
              >
                <div>
                  <strong>Id: {{ simulator.id }}.</strong>
                </div>
                <div>
                  <div>Название: {{ simulator.title }}</div>
                  <div>Категория: {{ simulator.category }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
      <Transition name="slide-fade">
        <div v-show="isEditing || open" class="collapse-text-card__body">
          <p v-if="loading">Загрузка данных...</p>
          <div v-else-if="methodist">
            <DiveInput label="ФИО" :value="editedMethodist.name" />
            <DiveTextarea label="Биография" :value="editedMethodist.bio" />
            <DiveInput label="Должность" :value="editedMethodist.position" />
            <div
              v-for="simulator in editedMethodist.application"
              :key="simulator.id"
            >
              <DiveInput label="Название" :value="simulator.title" />
              <DiveInput label="Категория" :value="simulator.category" />
              <DiveInput label="Задний фон" :value="simulator.background" />
            </div>
            <div class="collapse-text-card__edit-buttons-container">
              <DiveButton
                id="show-modal"
                text="Изменить"
                type="primary"
                size="medium"
                @click="showModal = true"
              />

              <Teleport to="body">
                <DialogModal :show="showModal" @close="showModal = false">
                  <template #header>
                    <h1>Изменение информации</h1>
                  </template>
                  <template #body>
                    Вы уверены, что хотите изменить информацию о методисте?
                  </template>
                </DialogModal>
              </Teleport>
              <DiveButton
                text="Отмена"
                type="secondary"
                size="medium"
                @click="toggleEditInfo"
              />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import DialogModal from '@/shared/ui/DialogModal.vue';
import MoreInfoIcon from '@/assets/images/icons/administrating/MoreInfoIcon.vue';
import EditIcon from '@/assets/images/icons/administrating/EditIcon.vue';
import DeleteBinIcon from '@/assets/images/icons/administrating/DeleteBinIcon.vue';
import StatusIcon from '@/assets/images/icons/administrating/StatusIcon.vue';
import DiveInput from '@/shared/ui/DiveInput.vue';
import DiveButton from '@/shared/ui/DiveButton.vue';
import DiveTextarea from '@/shared/ui/DiveTextаrea.vue';
import { useMethodistsStore } from '@/app/store/methodistsStore';
export default {
  name: 'AccordionListItem',
  components: {
    DiveButton,
    MoreInfoIcon,
    EditIcon,
    DeleteBinIcon,
    StatusIcon,
    DiveInput,
    DiveTextarea,
    DialogModal,
  },
  props: {
    title: String,
    category: String,
    description: String,
    methodistId: Number,
    open: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const methodistsStore = useMethodistsStore();
    const loading = ref(false);
    const isOpened = ref(false);
    const isEditing = ref(false);
    const methodist = ref(null);
    const editedMethodist = ref({
      id: '',
      name: '',
      bio: '',
      position: '',
      application: [],
    });
    const showModal = ref(false);
    const showChangeStatusModal = ref(false);
    const loadMethodist = async () => {
      if (!props.methodistId) return;
      loading.value = true;
      await methodistsStore.get_methodist(props.methodistId);
      methodist.value = methodistsStore.methodist;
      loading.value = false;
    };
    const toggleInfo = () => {
      isOpened.value = !isOpened.value;
      if (isOpened.value) {
        isEditing.value = false;
        if (!methodist.value) {
          loadMethodist();
        }
      }
    };

    const toggleEditInfo = async () => {
      isEditing.value = !isEditing.value;
      if (isEditing.value) {
        isOpened.value = false;
        if (!methodist.value) await loadMethodist();
        editedMethodist.value = { ...methodist.value };
      }
    };

    return {
      isOpened,
      isEditing,
      toggleInfo,
      toggleEditInfo,
      editedMethodist,
      methodist,
      loading,
      showModal,
      showChangeStatusModal,
    };
  },
};
</script>

<style scoped lang="scss">
.collapse-text-card-wrapper {
  .collapse-text-card {
    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-transform: uppercase;
      font-family:
        Proxima Nova,
        serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding: 15px;
      border-radius: 10px;
      background-color: #f0f1f5;
      color: #222e37;
      cursor: pointer;
      position: relative;
      z-index: 30;
      transition: all 0.3s ease;
      svg {
        transition: all 0.2s ease;
      }

      &:hover {
        background-color: #f7f6fb;
      }
    }

    .collapse-text-card__more-icon {
      flex-shrink: 0;
    }

    &__body {
      padding: 20px;
      border-top: 1px solid rgba(164, 166, 181, 0.98);
      font-family: Raleway, serif;
      font-size: 14px;
      font-style: normal;
      color: rgba(34, 46, 55, 0.7);
      font-weight: 500;
      line-height: 22px;
      border-radius: 0 0 10px 10px;
      background-color: #f0f1f5;
      cursor: pointer;
      position: relative;
      z-index: 23;

      .collapse-text-card__edit-buttons-container {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }
      .collapse-text-card__application-info {
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }

    &.active {
      .collapse-text-card__more-icon {
        transform: rotate(90deg);
      }
      .collapse-text-card__header {
        border-radius: 10px 10px 0 0;
        color: #0a7cff;
      }
    }
    .collapse-text-card__header-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .collapse-text-card__category {
      font-weight: 600;
    }
    .collapse-text-card__buttons-container {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  }
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateY(-40px);
    opacity: 0;
  }
}
</style>
