<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    x="0"
    y="0"
    viewBox="0 0 24 24"
    xml:space="preserve"
  >
    <g>
      <path
        d="m15.18 8.67-4.24 4.24-2.12-2.12-1.42 1.42L10.23 15a1 1 0 0 0 1.42 0l.7-.7 4.25-4.25z"
        fill="#39434B"
        opacity="1"
        data-original="#000000"
      ></path>
      <path
        d="M12 4a8 8 0 1 1-8 8 8 8 0 0 1 8-8m0-2a10 10 0 1 0 10 10A10 10 0 0 0 12 2z"
        fill="#39434B"
        opacity="1"
        data-original="#000000"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
  props: {
    color: {
      type: String,
      default: '#39434B',
    },
  },
};
</script>

<style scoped lang="scss"></style>
