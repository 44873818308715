<script>
import EyeOpened from '@/assets/images/icons/eye-open.svg';
import EyeClosed from '@/assets/images/icons/eye-closed.svg';

export default {
  name: 'DiveInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'medium',
    },
    value: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  emits: ['update:value', 'input', 'blur', 'focus', 'onEnter'],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    inputType() {
      return this.type === 'password' && this.showPassword ? 'text' : this.type;
    },
    eyeIcon() {
      return this.showPassword ? EyeOpened : EyeClosed;
    },
  },
  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<template>
  <div :class="['dive-input', { error: error, disabled: disabled }]">
    <label v-if="label" :class="{ 'label-error': error }">{{ label }}</label>
    <input
      :value="value"
      :type="inputType"
      class="only-line-text"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="$emit('update:value', $event.target.value)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @keydown.enter="$emit('onEnter')"
    />
    <img
      v-if="type === 'password'"
      :src="eyeIcon"
      class="eye-icon"
      :alt="showPassword ? 'Hide password' : 'Show password'"
      @click="toggleShowPassword"
    />
    <div class="error-message">
      <span v-if="error">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dive-input {
  width: 100%;
  position: relative;

  &.disabled .only-line-text {
    border-color: #d3d3d3;
    color: #d3d3d3;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  label {
    display: block;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #222e37;
    transition: color 0.3s;

    &.label-error {
      color: #ff4d4d;
    }
  }

  .only-line-text {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #222e37;
    padding: 15px 6px;
    border: none;
    border-bottom: 1px solid #222e37;
    background: transparent;
    width: 100%;
    box-sizing: border-box;

    transition:
      border-color 0.3s,
      color 0.3s,
      background-color 0.3s;

    &:hover:not(.disabled):not(.error) {
      border-bottom-color: #0a7cff;
    }

    &:focus:not(.disabled):not(.error) {
      border-bottom-color: #1e90ff;
      outline: none;
    }

    &::placeholder {
      color: #a4a6b5;
    }

    &:disabled {
      border-bottom-color: #d3d3d3;
      color: #d3d3d3;
      background-color: #f5f5f5;
      cursor: not-allowed;
    }
  }

  &.error .only-line-text {
    border-color: #ff4d4d;
    color: #ff4d4d;

    &::placeholder {
      color: #ff4d4d;
    }
  }

  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .error-message {
    margin-top: 4px;
    text-align: left;
    min-height: 14px;
    span {
      font-family: Raleway, serif;
      font-size: 14px;
      color: #ff4d4d;
    }
  }
}
</style>
